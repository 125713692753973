import React from 'react'
import { Helmet } from 'react-helmet'
import '../../styles/news.less'
import Logo from '../../images/logo.svg'
import bajutina from '../../images/bajutina.jpg'
import skolkovo from './img/skolkovo.png'
import ScrollToTopOnMount from '../../helpers/scrollRestorationFix'

const TITLE = 'Ипотека.Центр стал первым ипотечным проектом в «Сколково»'

const Skolkovo = () => {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content={TITLE} />
        <meta property="og:description" content={TITLE} />
      </Helmet>
      <ScrollToTopOnMount />
      <div className="news-layout">
        <header className="news-header">
          <a href="/" target="_blank">
            <img src={Logo} alt="logo" />
          </a>
        </header>
        <article className="news-article">
          <h1>{TITLE}</h1>
          <h3 className="news-article__lead">
            Платформа Ипотека.Центр&nbsp;&mdash; собственная российская
            разработка ООО &laquo;ФинМаркет&raquo; получила статус резидента
            &laquo;Сколково&raquo;. Облачный сервис был запущен в&nbsp;середине
            прошлого года и&nbsp;представляет собой виртуальное рабочее место
            для ипотечных агентов. Процесс построен на&nbsp;бесшовной интеграции
            с&nbsp;банками, страховыми и&nbsp;оценочными компаниями, что
            помогает оцифровать весь цикл сделки по&nbsp;ипотеке. Новый статус
            позволит проекту усилить позиции по&nbsp;технологическому развитию
            и&nbsp;предоставить пользователям ещё более выгодные условия для
            дистанционной работы.
          </h3>
          <p style={{ marginBottom: '30px' }} className="news-paragraph">
            30&nbsp;июня 2020, Москва&nbsp;&mdash; Платформа Ипотека.Центр стала
            первым ипотечным проектом, получившим статус резидента
            &laquo;Сколково&raquo;.
          </p>
          <img
            src={skolkovo}
            alt="skolkovo"
            loading="lazy"
            style={{ width: '100%', maxWidth: '650px', marginBottom: '35px' }}
          />
          <section className="news-text-section">
            <p className="news-paragraph">
              Разработка представляет собой бесплатный онлайн-сервис, который
              автоматизирует большинство рутинных операций ипотечных агентов
              (подбор программ, ввод документов) и&nbsp;позволяет дистанционно
              проводить полный цикл сделки для своих клиентов: от&nbsp;подачи
              заявки до&nbsp;регистрации права собственности.
            </p>
            <p className="news-paragraph">
              Платформа была запущена в&nbsp;июне 2019&nbsp;года,
              и&nbsp;на&nbsp;сегодняшний день её&nbsp;пользователями являются
              3526 профессионала ипотечного бизнеса из&nbsp;разных городов
              России.
            </p>
            <p className="news-paragraph">
              Проект получил высокую оценку экспертов инновационного центра
              &laquo;Сколково&raquo; с&nbsp;точки зрения стратегического
              развития отрасли и&nbsp;трендов на&nbsp;ускорение
              и&nbsp;цифровизацию процессов.
            </p>
            <p className="news-paragraph">
              Благодаря организационной и&nbsp;административной поддержке фонда
              руководство Ипотека.Центра планирует увеличить технологические
              мощности по&nbsp;развитию продукта, развить ещё более широкую сеть
              партнерств и&nbsp;обеспечить лучшие условия на&nbsp;рынке
              по&nbsp;агентскому вознаграждению.
            </p>
            <p className="news-paragraph">
              На&nbsp;текущий момент сервис уже предлагает своим пользователям
              одну из&nbsp;самых высоких агентских комиссий
              на&nbsp;рынке&nbsp;&mdash; до&nbsp;1% от&nbsp;суммы ипотечной
              сделки. А&nbsp;также даёт возможность получить новую актуальную
              профессию цифрового ипотечного агента.
            </p>
          </section>
          <section className="news-citations">
            <div className="news-citations__item">
              <p>
                Ипотека.Центр находится на&nbsp;стыке технологий
                и&nbsp;накопленного профессионального опыта в&nbsp;области
                ипотеки. При создании платформы одной из&nbsp;важных целей для
                нас было сделать максимально удобный и&nbsp;понятный инструмент
                для работы ипотечных специалистов. Для этого мы&nbsp;собрали
                лучшую команду в&nbsp;IT и&nbsp;профи из&nbsp;ипотечной сферы.
                Важным источником улучшений для нас всегда была обратная связь
                от&nbsp;самих пользователей продукта, наших бизнес-партнёров.
                Благодаря такому подходу нам удалось создать продукт,
                предвосхитивший потребность рынка в&nbsp;решениях
                по&nbsp;трансформации классических бизнес-моделей в&nbsp;онлайн.
                Впереди у&nbsp;нас большие планы по&nbsp;развитию продукта
                и&nbsp;сотрудничество со&nbsp;&laquo;Сколково&raquo;,
                безусловно, открывает для нас в&nbsp;этом плане ещё большие
                перспективы.
              </p>
              <div className="news-citations__author">
                <img src={bajutina} alt="Ольга Бажутина" />
                <div>
                  <h5>Ольга Бажутина</h5>
                  <span>генеральный директор Ипотека.Центр</span>
                </div>
              </div>
            </div>
          </section>
        </article>
        <footer className="news-footer">
          <h6 className="news-footer__title">Справка</h6>
          <p className="news-paragraph">
            Ипотека.Центр&nbsp;&mdash; ипотечная&nbsp;IT компания, находящаяся
            на&nbsp;стыке облачных технологий и&nbsp;реального бизнеса. Компания
            берёт своё начало с&nbsp;августа 2017&nbsp;года. Является ТМ ООО
            &laquo;ФинМаркет&raquo;{' '}
            <a href="/" target="_blank">
              https://ipoteka.center
            </a>
          </p>
          <p className="news-paragraph">
            Платформа Ипотека.Центр&nbsp;&mdash; собственная российская
            разработка ООО &laquo;ФинМаркет&raquo;. Инновационный сервис для
            профессионалов ипотечного бизнеса, позволяет проводить полный цикл
            сделки по&nbsp;ипотечному кредиту онлайн и&nbsp;получать высокое
            агентское вознаграждение. Платформа была запущена в&nbsp;июне
            2019&nbsp;года.
          </p>
          <p className="news-paragraph">
            Контакты для&nbsp;прессы:{' '}
            <a href="mailto:pr@ipoteka.center">pr@ipoteka.center</a>
          </p>
        </footer>
      </div>
    </>
  )
}

export default Skolkovo
